import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import styles from './ClientDetail.scss';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import AdvisorClientKeyNote from './ClientKeyNote';
import AdvisorClientChatHistory from './ClientChatHistory';
import { useEffect } from 'react';
import { getAvatarUrl, scrollUpTop, v_url } from '../../../Utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import FloatBar from '../../../Components/FloatBar/FloatBar';
import ApiService from '../../../Core/Service/ApiService';
import { useSelector } from 'react-redux';
import { IsChatSocketConnected } from '../../../Store/Reducer/socketReducer';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';

const AdvisorClientDetail = React.memo(props=>{
    const params = useParams();
    const cId = params.cId;
    const navigate = useNavigate();
    const [client, setClient] = useState();
    const [loading, setLoading] = useState(false);
    const isChatSockConnected = useSelector(IsChatSocketConnected);

    const goBack = () => {
        navigate(v_url('/advisor/dashboard'))
    }

    useEffect(()=>{
        scrollUpTop();
    }, [])

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/advisor/client/show/'+cId).then(response => {
            setClient(response.data.user);
        }).finally(() => {
            setLoading(false);
        }) 
    }, [cId]);

    const renderFloatBar = () => {
        return (
            <FloatBar triggerY={145} mobile_float_y={60} pc_float_y={80}>
                <MDBBtn 
                    color='none' tag='a'
                    className='p-1'
                    onClick={goBack}
                >
                    <SVG className='go-back-icon' src='/img/icons/angle-left.svg' />
                </MDBBtn>
                <img className="ms-1 circle-avatar-30" src={getAvatarUrl(client?.avatar)} />
                <span className='ms-2'>{ client?.username }</span>
            </FloatBar>
        )
    }

    return <MDBContainer breakpoint='lg' className='advisor-client-detail p-2 p-md-4 position-relative'>
        {renderFloatBar()}

        <div className='go-back-bar'>
            <MDBBtn color='none' tag='a'
                className='p-1' 
                onClick={goBack}
            >
                <SVG className='go-back-icon' src='/img/icons/angle-left.svg' />
            </MDBBtn>
        </div>

        <AdvisorClientKeyNote client={client} />
        <AdvisorClientChatHistory client={client} />

        {loading && isChatSockConnected && <LoadingIndicator fullScreen={true} />}
    </MDBContainer>
});

export default AdvisorClientDetail;