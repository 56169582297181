import React, {useEffect} from "react";
import Introduction from "./Introdution";
import Statistics from "./Statistics";
import TopAdvisors from "./TopAdvisors";
import AdvisorList from "./Advisors";
import WhyChooseUs from "./WhyChooseUs";
import NatalChart from "./NatalChart";
import ConsultAreas from "./ConsultAreas";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { purgeAuth } from "../../Store/Reducer/authReducer";

function HomePage(props) {
    // console.log('calling Hompage...')
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, []);

    return (
        <section>
            <Introduction />
            {/*<Statistics /> */}
            {/* <TopAdvisors /> */}
            <AdvisorList />
            <WhyChooseUs />
            {/* <NatalChart /> */}
            {/* <ConsultAreas /> */}
        </section>
    )
}

export default HomePage;