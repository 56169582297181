import React, { useEffect, useState } from 'react';
import ApiService from '../../../Core/Service/ApiService';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBIcon, MDBInput, MDBSpinner } from 'mdb-react-ui-kit';
import { floatV } from '../../../Utils/utils';
import { toast } from 'react-toastify';
import { PaymentKind } from '../../../Constants/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { memoizedGetAuthUser } from '../../../Store/Reducer/authReducer';

const AdvisorRevenue = React.memo(props=>{
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    const { advisorDetail } = props;

    return <MDBCard className="advisor-earning-card mt-4">
        <MDBCardHeader className="d-flex align-items-center">
            <MDBIcon fas icon='money-bill' color="info" size='2x' />
            <span className="px-2"><strong>My Revenue</strong></span>
        </MDBCardHeader>
        <MDBCardBody>
            <div className='my-earning d-flex'>
                <div className='available-balance d-flex align-items-center'>
                    <label>Balance:</label>
                    <div className='value ms-1'>$ {floatV(advisorDetail?.balance)}</div>
                </div>
            </div>
        </MDBCardBody>
    </MDBCard>
})

export default AdvisorRevenue;
