import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCheckbox, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React, { useContext } from "react";
import styles from './Advisor.scss';
import PersonalData from "./Partials/PersonalData";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SkillServiceData from "./Partials/SkillServiceData";
import ApplicantDocument from "./Partials/ApplicantDocument";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserStatus } from "../../../Constants/constants";
import Select from "react-select";
import { toast } from "react-toastify";
import PayoutMethod from "./Partials/PayoutMethod";
import { getBackendAssetUrl } from "../../../Utils/utils";
import ModalContext from "../../../Context/ModalContext";

const AdminAdvisorEdit = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const aId = params.aId;
    const userStatusChgOpts = [
        { value: UserStatus.APPROVED, label: "Approve" },
        { value: UserStatus.SUSPENDED, label: "Suspend"}
    ];
    const [updatingUserStatus, setUpdatingUserStatus] = useState(false);
    const [formData, setFormData] = useState({
        aId: '',
        commission: 0,
        status: '',
        listing: false,
        show_video: false,
        memo: ''
    })
    const modalContext = useContext(ModalContext);

    useEffect(()=>{
        setLoading(true);

        const url = `/admin/advisor/${aId}`;
        ApiService.get(url).then(result=>{
            const ad = result.data.advisor;
            setAdvisor(ad);
            setFormData({
                aId: ad._id,
                commission: ad.commission,
                status: ad.user_status,
                listing: ad.listing,
                show_video: ad.show_video,
                memo: ad.memo
            })
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const onUserStatusChg = (opt) => {
        setFormData({
            ...formData,
            status: opt.value
        })
    }

    const onListingChanged = (e) => {
        setFormData({
            ...formData,
            listing: e.target.checked
        })
    }

    const onShowVideoChanged = (e) => {
        setFormData({
            ...formData,
            show_video: e.target.checked
        })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        if(window.confirm("Are you sure to save?") == false) return;

        setUpdatingUserStatus(true);

        ApiService.post('/admin/advisor/update', formData).then(result => {
            toast.success("Advisor information was updated successfully");
            // navigate('/admin/advisor/list');
        })
        .finally(()=>{
            setUpdatingUserStatus(false);
        })
    }

    const handleCreditBack = () => {
        modalContext.setModalData({user: advisor});
        modalContext.setOpenCreditBackModal(true);
    }


    const breadcrumb = [];
    breadcrumb.push({title: 'Advisor', url:'/admin/advisor/list'});
    breadcrumb.push({title: 'Show'});

    return <MDBContainer breakpoint="lg" className="admin-advisor-show-container">
        <Breadcrumb breadcrumb={breadcrumb} className='mb-4' />
        <MDBCard>
            <MDBCardHeader>
                <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                <span className="accordion-item-title">Personal Information</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PersonalData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="atom" size='lg' className="me-1" />
                <span className="accordion-item-title">Services</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <SkillServiceData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="atom" size='lg' className="me-1" />
                <span className="accordion-item-title">Payout Methods</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PayoutMethod advisor={advisor} setAdvisor={setAdvisor} /> }
            </MDBCardBody>
        </MDBCard>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon far icon="id-card" className="me-1" />
                <span className="accordion-item-title">Application Documents</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <ApplicantDocument advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        {advisor?.video && (
            <MDBCard className="mt-4">
                <MDBCardHeader>
                    <MDBIcon fas icon="video" className="me-1" />
                    <span className="accordion-item-title">Introduction Video</span>
                </MDBCardHeader>
                <MDBCardBody className="d-flex justify-content-center p-4">
                    <video 
                        src={getBackendAssetUrl(advisor.video)}
                        style={{width: 400, height: 300}}
                        controls
                    />
                </MDBCardBody>
            </MDBCard>
        )}

        <div className="mt-4 user-memo">
            <div className="px-2">Memo : </div>
            <div className="flex-fluid">
                <MDBTextArea label=""
                            name='memo'
                            value={formData.memo}
                            rows={4}
                            style={{backgroundColor: 'white'}}
                            onChange={e=>handleInputChange(e)}
                />
                <div className="notify">
                    ( This message won't be showed to the advisor, instead it is only for admin's reference.) 
                </div>
            </div>
        </div>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                <span className="accordion-item-title">Action</span>
            </MDBCardHeader>
            <MDBCardBody className="d-flex">
                <MDBRow className="w-100">
                    <MDBCol size={12} sm={6} lg={4} xl={3} className="d-flex align-items-center mb-2">
                        <label className="w-sm-100px">Commission:</label>
                        <div className="ms-2 d-flex align-items-center w-150px">
                            <MDBInput type='number' 
                                name='commission'
                                value={formData.commission} 
                                onChange={handleInputChange}
                                style={{textAlign:'center'}}
                            />
                        </div>
                        <div className="ms-1">%</div>
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center mb-2" size={12} sm={6} lg={4} xl={3}>
                        <label className="w-sm-100px">Status : </label>
                        <div className="ms-2 w-150px">
                            <Select 
                                options={userStatusChgOpts} 
                                onChange={opt=>onUserStatusChg(opt)} 
                                value={userStatusChgOpts.find(opt=>opt.value == formData.status)}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center mb-2" size={12} sm={6} md={4} lg={3} xl={3}>
                        <label className="w-sm-100px">Listing:</label>
                        <MDBCheckbox 
                            className="ms-2" 
                            checked={formData.listing}
                            onChange={onListingChanged}
                        />
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center mb-2" size={12} sm={6} md={4} lg={3} xl={2}>
                        <label className="w-sm-100px">Show Video:</label>
                        <MDBCheckbox 
                            className="ms-2" 
                            checked={formData.show_video}
                            onChange={onShowVideoChanged}
                        />
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center mb-2" size={12} md={4} lg={3} xl={2}>
                        <label className="w-sm-100px">Credit</label>
                        <MDBBtn
                            className="ms-2"
                            color="warning p-1 px-3"
                            onClick={handleCreditBack}
                        >
                            Back
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={handleUpdate} className="mx-2">
                {updatingUserStatus && <>
                    <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                    Saving
                </>}
                {!updatingUserStatus && <span>Save</span>}
            </MDBBtn>
        </div>
        {loading && <LoadingIndicator />}
    </MDBContainer>
});

export default AdminAdvisorEdit;