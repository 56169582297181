import React from "react";
import { format_time_mm_ss, getAvatarUrl } from "../../Utils/utils";
import Moment from "react-moment";
import { MDBBtn, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { UserRole } from "../../Constants/constants";
import Advisor from '../../Components/Advisor/Advisor'
import { useNavigate } from "react-router-dom";

const ChatMetaInfoAdmin = React.memo((props) => {
    const navigate = useNavigate();
    const {chat} = props;

    const goBack = () => {
        navigate(-1);
    }

    return <div className="chat-metainfo-admin d-none d-md-flex flex-column align-items-center justify-content-center ">
        <div style={{color: "purple"}} className="m-2">
            <h5><Moment format='YYYY-MM-DD HH:mm'>{chat.startedAt}</Moment></h5>
        </div>

        <div className="chat-client d-flex flex-column align-items-center py-3">
            <div className="mb-2"><strong>Client</strong></div>
            <img src={getAvatarUrl(chat?.cId?.avatar)} />
            <div>{ chat?.cId?.username }</div>
        </div>

        <div className="chat-advisor d-flex flex-column align-items-center py-3 mt-2">
            <div className="mb-2"><strong>Advisor</strong></div>
            <img src={getAvatarUrl(chat?.aId?.avatar)} />
            <div>{ chat?.aId?.username }</div>
        </div>

        <div className="other-infos w-100 mt-4 d-flex justify-content-center">
            <div>
                <div className="chat-duration mt-4 d-flex">
                    <div className="label">Duration</div>
                    <div className="mx-2">:</div>
                    <div style={{color: "blue"}}>{format_time_mm_ss(chat.duration)}</div>
                </div>

                <div className="rate mt-4 d-flex">
                    <div className="label">Rate</div>
                    <div className="mx-2">:</div>
                    <div style={{color: "blue"}}>${chat.rate_per_min_chat}</div>
                </div>

                <div className="amount mt-4 d-flex">
                    <div className="label">Amount</div>
                    <div className="mx-2">:</div>
                    <div style={{color: 'blue'}}>${chat.amount}</div>
                </div>
            </div>
        </div>
    </div>
});

export default ChatMetaInfoAdmin;