import React, { useEffect, useState } from "react";
import Select from "react-select";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Paginator from "../../../Components/Paginator/Paginator";
import Moment from "react-moment";
import './Transaction.scss';
import SearchBar from "../../../Components/SearchBar/SearchBar";
import { MDBBtn, MDBContainer, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import LiteDatePicker from "../../../Components/DatePicker/LiteDatePicker";
import { useNavigate } from "react-router-dom";
import { formatDate_YYYY_MM_DD, v_url } from "../../../Utils/utils";
import { hookTimer } from "../../../Utils/timer";

const AdminPendingPayoutList = React.memo((props) => {
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(0);
    const [pendingPayoutList, setPendingPayoutList] = useState([]);
    const navigate = useNavigate();

    let endDateTS = localStorage.getItem('confideas_endDateTS');
    if(endDateTS) {
        endDateTS = parseInt(endDateTS);
    } else {
        endDateTS = new Date().getTime();
    }
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        endDate: formatDate_YYYY_MM_DD(new Date(endDateTS))
    });

    useEffect(()=>{
        getPendingPayoutList();
        hookTimer('update_pending_payout', 10*1000, getPendingPayoutList);
    }, [formData]);

    const getPendingPayoutList = () => {
        setLoading(true);
        ApiService.get('/admin/transaction/pending_payout', formData).then(response => {
            setPendingPayoutList(response.data.pendingPayoutList);
            setTotal(response.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onSearchBtnClicked = (e) => {
        getPendingPayoutList();
    }
    const onDateSelected = (date) => {
        setFormData({
            ...formData,
            endDate: formatDate_YYYY_MM_DD(date)
        });
        localStorage.setItem('confideas_endDateTS', date.getTime());
    }

    const onRowSelected = (rowIndex, row) => {
        navigate(v_url(`/admin/transaction/pending_payout/detail/${row.aId._id}/${formData.endDate}`));
    }

    const columns = [
        {name: 'username', title: 'Advisor'},
        {name: 'total_amount', title: 'Total Amount'},
    ]

    const breadcrumb=[{title: 'Transaction'}, {title: 'Payouts in pending'}];

    return (<>
        <MDBContainer breakpoint="lg" className="admin-pending-payout-list">
            <Breadcrumb breadcrumb={breadcrumb} />
            <div className="d-flex align-items-center">
                <div className="flex-fluid">Total: {total}</div>
                <SearchBar>
                    <div className="search-item search-term me-2" style={{width: 160}}>
                        <LiteDatePicker 
                            selectedDate={ new Date(endDateTS) } 
                            onDateSelected={onDateSelected} />
                    </div>
                    <div className="search-item me-2">
                        <Select 
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            defaultValue={curPerPageOpt} 
                        />
                    </div>
                    <div className='search-item search-term d-flex align-items-center' style={{width: 180}}>
                        <MDBInput type="text" 
                                label="Advisor's username"
                                value={search}
                                onChange={e=>setSearch(e.target.value)}
                                onKeyDown={onSearchKeyDown}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>                
            </div>

            <div className="mt-4">
                <AdminTable columns={columns} rows={pendingPayoutList} onRowSelected={onRowSelected}>
                    {(row, colname, rowIndex, colIndex) => {
                        switch(colname) {
                            case 'username':
                                return <div className="d-flex align-items-center">
                                    <img src={row.aId.avatar} className="circle-avatar-40" />
                                    <span className="ms-2">{row.aId.username}</span>
                                    {row.aId.is_withdrawing && <span className="payout-processing ms-4">Processing</span>}
                                </div>
                            case 'total_amount': 
                                return <div className="d-flex">
                                    <div className="flex-fluid">$ {row.total_amount}</div>
                                    <div><MDBIcon fas icon="angle-right" size="lg" /></div>
                                </div>
                        }
                    }}
                </AdminTable>

                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
                </div>

                {loading && <LoadingIndicator />}
            </div>
        </MDBContainer> 
    </>)
});

export default AdminPendingPayoutList;


