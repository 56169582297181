import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead, MDBInput, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import React, {useEffect} from "react";
import SVG from 'react-inlinesvg';
import styles from './ChatHistory.scss'
import { useState } from "react";
import ApiService from "../../Core/Service/ApiService";
import Select from "react-select";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import Moment from "react-moment";
import { addDate, compareDates, formatSeconds, format_time_mm_ss, getAvatarUrl, getTimeDiff, v_url } from "../../Utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import Paginator from "../Paginator/Paginator";
import { useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { ChatStatus, EDIT_REVIEW_DAYS_OUT, REVIEW_CHAT_DURAION, ReviewType, UserRole } from "../../Constants/constants";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import EventContext from "../../Context/EventContext";
import ScrollHorizon from "../ScrollHorizon/ScrollHorizon";
import SearchBar from "../SearchBar/SearchBar";
import { getPreviousUrl } from "../../Store/Reducer/urlHistoryReducer";

const ChatHistory = React.memo((props) => {
    const navigate = useNavigate();
    const previousUrl = useSelector(getPreviousUrl())
    const params = useParams();
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState([]);
    const [total, setTotal] = useState(0);
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [searchPartner, setSearchPartner] = useState(params.partner ?? '');
    const [searchFormData, setSearchFormData] = useState({
        searchPartner: params.partner ?? '',
        pageNum: 1,
        perPage: 10
    });
    const modalContext = useContext(ModalContext);
    const eventContext = useContext(EventContext);

    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onSearchPartnerChanged = (e) => {
        setSearchPartner(e.target.value);
    }
    const onSearchPartnerKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                searchPartner
            });
        }
    }
    const onSearchBtnClicked = () => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            searchPartner
        });
    }

    useEffect(()=>{
        getChats();
    }, [searchFormData.perPage, searchFormData.pageNum, searchFormData.searchPartner]);
    useEffect(()=>{
        if(eventContext.evtReviewChanged) {
            getChats();
            eventContext.setEvtReviewChanged(null);
        }
    }, [eventContext.evtReviewChanged])

    useEffect(()=>{
        getChats();
    }, [eventContext.evtAdvisorNoteChanged]);

    const getChats = () => {
        setLoading(true);

        const data = {
            pageNum: searchFormData.pageNum,
            perPage: searchFormData.perPage,
            searchPartner: searchFormData.searchPartner.trim()
        }

        ApiService.post(`/${authUser?.role}/chat/history`, data).then(result=>{
            setChats(result.data.chats);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onChatClick = (chat) => {
        navigate(v_url(`/chat/${chat._id}/detail`));
    }

    const onEditReview = (chat) => {
        modalContext.setModalData({chat});
        modalContext.setOpenEditReviewModal(true);
    }

    const onDeleteReview = (chat) => {
        if(window.confirm('Are you sure to delete review?') === false) return;
        ApiService.get('/client/review/remove/' + chat.reviewId._id).then(result=>{
            eventContext.setEvtReviewChanged({
                time: new Date(),
                action: 'delete'
            });
        })
        .finally(()=>{
            
        })
    }

    const onEditNoteBtnClicked = (chat) => {
        modalContext.setModalData({
            client: chat.cId,
            chat: chat
        });
        modalContext.setOpenAdvisorNoteModal(true);
    }
    const onDeleteNoteBtnClicked = (chat) => {
        if(window.confirm('Are you sure to delete?') === false) return;

        const data = {
            advisorNoteId: chat.advisorNoteId,
            chatId: chat._id
        }
        ApiService.post('/advisor/note/remove', data).then(result=>{
            eventContext.setEvtAdvisorNoteChanged({
                time: new Date(),
                action: 'delete'
            })
        })
        .finally(()=>{

        })
    }

    const renderChatsPC = () => {
        return (
        <section className="d-none d-sm-block">
            <MDBTable align="middle">
                <MDBTableHead>
                    <tr>
                        <th>Date</th>
                        <th>{authUser?.role === UserRole.CLIENT ? 'Advisor' : 'Client'}</th>
                        <th>Transcript</th>
                        <th>Review</th>
                        {authUser?.role === UserRole.ADVISOR && <>
                            <th>Note</th>
                            <th>Clear In</th>
                        </>}
                        <th>Duration</th>
                        <th>{authUser?.role === UserRole.CLIENT ? 'Amount' : 'My Earnings'}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {(() => {
                        if(chats?.length > 0) {
                            return <>
                                { chats.map((chat, index)=>{
                                    const partner = authUser?.role === UserRole.CLIENT ? chat.aId : chat.cId;
                
                                    return (
                                        <tr key={index}>
                                            <td className="chat-time"><Moment format="YYYY-MM-DD">{chat.startedAt}</Moment></td>                            
                                            <td className="partner">
                                                <img src={getAvatarUrl(partner?.avatar)} className="me-2 circle-avatar-40" />
                                                {partner?.username}
                                            </td>
                                            <td>
                                                <SVG src='/img/icons/chat-list.svg'
                                                    className="cursor-pointer"
                                                    style={{width:25, height: 25}}
                                                    title="View Chat Content"
                                                    onClick={(e) => onChatClick(chat)}
                                                    fill="#cd730c"
                                                />
                                            </td>
                                            <td>
                                            { (() => {
                                                if(authUser?.role === UserRole.CLIENT && chat.duration > REVIEW_CHAT_DURAION) {
                                                    const expireDate = addDate(new Date(chat.startedAt), EDIT_REVIEW_DAYS_OUT);
                                                    if(compareDates(expireDate, new Date()) === 1) {
                                                        return <>
                                                            <MDBIcon fas icon="edit" 
                                                                size="lg" 
                                                                color="warning" 
                                                                className="cursor-pointer" 
                                                                title='Edit' 
                                                                onClick={(e) => onEditReview(chat)}
                                                            />
                                                            {chat.reviewId && 
                                                            <MDBIcon fas icon='trash-alt' 
                                                                size="lg" 
                                                                color="danger" 
                                                                className="cursor-pointer ms-1" 
                                                                title='Delete' 
                                                                onClick={e => onDeleteReview(chat)}
                                                            />
                                                            }
                                                        </>
                                                    }
                                                } 
                
                                                if(chat.reviewId) {
                                                    return <>
                                                        {chat.reviewId &&
                                                        <img src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`} 
                                                            style={{width:30}} 
                                                            className="cursor-pointer"
                                                            onClick={e=>onEditReview(chat)}
                                                        />
                                                        }
                                                    </>
                                                }
                                            })()}
                                            </td>
                                            {authUser?.role === UserRole.ADVISOR && <>
                                                <td>
                                                    <MDBIcon fas icon='edit' size='lg' color='warning' className='cursor-pointer' 
                                                        onClick={(e)=>onEditNoteBtnClicked(chat)} />
                                                    {chat.advisorNoteId && 
                                                    <MDBIcon fas icon='trash-alt' size='lg' color='danger' className='cursor-pointer ms-2'
                                                        onClick={(e)=>onDeleteNoteBtnClicked(chat)} />
                                                    }
                                                </td>
                                                <td>
                                                    <div className={`billing ${chat.status == ChatStatus.BILLED ? 'billed' : 'pending'}`}
                                                        title={chat.status == ChatStatus.BILLED ? 'Completed' : 'Pending'}
                                                    >
                                                        {chat.status == ChatStatus.ENDED && chat.clearIn}
                                                        {chat.status == ChatStatus.BILLED && <Moment format="YYYY-MM-DD">{chat.bill_time}</Moment>}
                                                    </div>
                                                </td>
                                            </>}
                                            <td className="duration">{ format_time_mm_ss(chat.duration) }</td>
                                            <td className="amount">$ {authUser?.role === UserRole.CLIENT ? chat.amount : chat.ad_earning}</td>
                                        </tr>
                                    )})
                                }
                            </>
                        } else {
                            return <tr>
                                <td colSpan={7} className="text-center">
                                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Chats</div>
                                    </div>}
                                </td>
                            </tr>
                        }
                    })()}
                </MDBTableBody>
            </MDBTable>
        </section>
        )
    }

    const renderChatsMobile = () => {
        return (
        <section className="d-block d-sm-none">
            <MDBTable align="middle">
                <MDBTableHead>
                    <tr>
                        <th className="p-2 text-center">Date</th>
                        <th className="p-2">Review</th>
                        <th className="p-2">Transcript</th>
                        {authUser?.role === UserRole.ADVISOR && <>
                            <th className="p-2">Note</th>
                        </>}
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {(() => {
                        if(chats?.length > 0) {
                            return <>
                                { chats.map((chat, index)=>{
                                    const partner = authUser?.role === UserRole.CLIENT ? chat.aId : chat.cId;
                
                                    return (
                                        <tr key={index}>
                                            <td className="partner p-2">
                                                <div className="d-flex align-items-center">
                                                    <img src={getAvatarUrl(partner?.avatar)} className="me-2 circle-avatar-40" />
                                                    <div className="mx-1">
                                                        <div className="fw-bold">{partner?.username}</div>
                                                        <div><Moment format="YYYY-MM-DD">{chat.startedAt}</Moment></div>
                                                        <div>{ formatSeconds(chat.duration) }</div>
                                                        <div style={{
                                                            borderRadius: 5,
                                                            border: '2px solid gold',
                                                            width: 'fit-content',
                                                        }} className="px-2">
                                                            $ {authUser?.role === UserRole.CLIENT ? chat.amount : chat.ad_earning}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="p-2 text-center">
                                            { (() => {
                                                if(authUser?.role === UserRole.CLIENT && chat.duration > REVIEW_CHAT_DURAION) {
                                                    const expireDate = addDate(new Date(chat.startedAt), EDIT_REVIEW_DAYS_OUT);
                                                    if(compareDates(expireDate, new Date()) === 1) {
                                                        return <>
                                                            <MDBIcon fas icon="edit" 
                                                                size="lg" 
                                                                color="warning" 
                                                                className="cursor-pointer" 
                                                                title='Edit' 
                                                                onClick={(e) => onEditReview(chat)}
                                                            />
                                                            {chat.reviewId && 
                                                            <MDBIcon fas icon='trash-alt' 
                                                                size="lg" 
                                                                color="danger" 
                                                                className="cursor-pointer ms-1" 
                                                                title='Delete' 
                                                                onClick={e => onDeleteReview(chat)}
                                                            />
                                                            }
                                                        </>
                                                    }
                                                } 
                
                                                if(chat.reviewId) {
                                                    return <>
                                                        {chat.reviewId &&
                                                        <img src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`} 
                                                            style={{width:30}} 
                                                            className="cursor-pointer"
                                                            onClick={e=>onEditReview(chat)}
                                                        />
                                                        }
                                                    </>
                                                }
                                            })()}
                                            </td>
                                            <td className="p-2 text-center">
                                                <SVG src='/img/icons/chat-list.svg'
                                                    className="cursor-pointer"
                                                    style={{width:25, height: 25}}
                                                    title="View Chat Content"
                                                    onClick={(e) => onChatClick(chat)}
                                                    fill="#cd730c"
                                                />
                                            </td>
                                            {authUser?.role === UserRole.ADVISOR && <>
                                                <td className="p-2">
                                                    <MDBBtn tag='a' color="none" onClick={(e)=>onEditNoteBtnClicked(chat)}>
                                                        <MDBIcon fas icon='edit' size='lg' color='warning' />
                                                    </MDBBtn>

                                                    {chat.advisorNoteId && 
                                                    <MDBBtn tag='a' color="none" onClick={(e)=>onDeleteNoteBtnClicked(chat)}>
                                                        <MDBIcon fas icon='trash-alt' size='lg' color='danger'/>
                                                    </MDBBtn>
                                                    }
                                                </td>
                                            </>}
                                        </tr>
                                    )})
                                }
                            </>
                        } else {
                            return <tr>
                                <td colSpan={7} className="text-center">
                                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Chats</div>
                                    </div>}
                                </td>
                            </tr>
                        }
                    })()}
                </MDBTableBody>
            </MDBTable>
        </section>
        )
    }

    const goBack = (e) => {
        if(previousUrl) {
            const url = previousUrl;
            dispatch(popHistory())
            navigate(url);
        }
    }
    
    return <MDBContainer breakpoint="lg" className="chat-all-history-container p-4">
        {/* <Breadcrumb breadcrumb={breadcrumb} /> */}
        <div className="title d-flex align-items-center d-none d-md-flex mx-1">
            Chat History
        </div>

        <div className="confideas-table-header">
            <SearchBar>
                <Select className="search-item me-2"
                        options={perPageOpts} 
                        onChange={(opts) => onPerPageChanged(opts)} 
                        defaultValue={curPerPageOpt} />
                <div className='search-item search-term'>
                    <MDBInput type="text" 
                            label="Search"
                            value={searchPartner}
                            onChange={onSearchPartnerChanged}
                            onKeyDown={onSearchPartnerKeyPress}
                    />
                    <span className='mx-2 d-flex align-items-center cursor-pointer' onClick={onSearchBtnClicked}>
                        <MDBIcon fas icon='search' />
                    </span>
                </div>
            </SearchBar>
        </div>

        <ScrollHorizon className="chat-history">
            {renderChatsPC()}
            {renderChatsMobile()}
        </ScrollHorizon>

        <div className='d-flex justify-content-center mt-4'>
            <Paginator total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
        </div>

        {loading && <LoadingIndicator />}
    </MDBContainer>
})

export default ChatHistory;