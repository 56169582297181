import { useSelector } from "react-redux";
import classname from 'classnames';
import AboutUs from "../AboutUs/AboutUs";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsOfuse from "../TermsOfUse/TermsOfUse";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import { UserRole } from "../../../Constants/constants";
import AdvisorTerms from "../AdvisorTerms/AdvisorTerms";
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import './NavBar.scss';
import { Link, useLocation } from "react-router-dom";
import { v_url } from "../../../Utils/utils";

const InfoCenterNavBar = () => {
    const authUser = useSelector(memoizedGetAuthUser);
    const location = useLocation();
    const {pathname} = location;

    return (
        <div className="d-block d-md-none py-2 px-4 info-navbar" >
            {authUser?.role === UserRole.ADVISOR && (
                <MDBRow>
                    <MDBCol size={6}>
                        <Link to={v_url('/about_us')} className={classname("underline", pathname==v_url('/about_us') ? 'active':'')}>About Confideas</Link>
                    </MDBCol>
                    <MDBCol size={6} className="d-flex justify-content-end align-items-center">
                        <Link to={v_url('/terms_of_use')} className={classname("underline", pathname==v_url('/terms_of_use') ? 'active':'')}>Terms of Use</Link>
                    </MDBCol>
                    <MDBCol size={4}>
                        <Link to={v_url('/privacy_policy')} className={classname("underline", pathname==v_url('/privacy_policy') ? 'active':'')}>Privacy Policy</Link>
                    </MDBCol>
                    <MDBCol size={8} className="d-flex justify-content-end align-items-center">
                        <Link to={v_url('/advisor_terms')} className={classname("underline", pathname==v_url('/advisor_terms') ? 'active':'')}>Advisor Terms and Conditions</Link>
                    </MDBCol>
                </MDBRow>
            )}
            {(!authUser || authUser.role != UserRole.ADVISOR) && (
                <div className="d-flex justify-content-between">
                    <Link to={v_url('/about_us')} className={classname("underline", pathname==v_url('/about_us') ? 'active':'')}>About Confideas</Link>
                    <Link to={v_url('/terms_of_use')} className={classname("underline", pathname==v_url('/terms_of_use') ? 'active':'')}>Terms of Use</Link>
                    <Link to={v_url('/privacy_policy')} className={classname("underline", pathname==v_url('/privacy_policy') ? 'active':'')}>Privacy Policy</Link>
                </div>
            )}
        </div>
    )
}

export default InfoCenterNavBar;